module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4RVCLWKBCS"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MIBA soft webdesign vývoj webových prezentací.","short_name":"mibasoft.cz","description":"Vytvořím Vám rychle a levně profesionální webové stránky. Nejlepší poměr cena/výkon. Neváhejte se ozvat ještě dnes pro výhodnou nabídku.","start_url":"/","lang":"cs","display":"standalone","locale":"cs_CZ","type":"website","og":{"description":"Vytvořím Vám rychle a levně profesionální webové stránky. Nejlepší poměr cena/výkon. Neváhejte se ozvat ještě dnes pro výhodnou nabídku.","locale":"cs_CZ","type":"website","url":"https://www.mibasoft.cz","title":"MIBA soft webdesign vývoj webových prezentací.","image":"images/miba_web01.png"},"icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/favicon.ico","sizes":"16x16","type":"image/ico"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
